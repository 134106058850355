import { Injectable, Signal } from '@angular/core';
import { Store } from '@ngrx/store';

import { CostCenterListItem } from '@mp/organization-master-data/cost-centers/domain';

import { CostCentersActions } from './cost-centers.actions';
import { selectCostCenters, selectCostCentersLoaded } from './cost-centers.selectors';

@Injectable()
export class CostCentersFacade {
  readonly costCenters: Signal<CostCenterListItem[]> = this.store$.selectSignal(selectCostCenters);

  readonly costCentersLoaded: Signal<boolean> = this.store$.selectSignal(selectCostCentersLoaded);

  constructor(private readonly store$: Store) {}

  fetchCostCenters(searchTerm?: string): void {
    this.store$.dispatch(CostCentersActions.fetchCostCenters({ searchTerm }));
  }

  upsertCostCenterToList(costCenter: CostCenterListItem): void {
    this.store$.dispatch(CostCentersActions.upsertCostCenterToList({ costCenter }));
  }
}
