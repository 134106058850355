import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';

import { CostCenterListItem } from '@mp/organization-master-data/cost-centers/domain';

import { CostCentersActions } from './cost-centers.actions';

export const costCentersFeatureKey = 'mpomd-core-cost-centers';

type CostCentersEntityState = EntityState<CostCenterListItem>;

export const costCentersEntityAdapter = createEntityAdapter<CostCenterListItem>({
  selectId: ({ costCenterId }: CostCenterListItem) => costCenterId,
});

export interface CostCentersState {
  costCenters: CostCentersEntityState;
  costCentersLoaded: boolean;
}

export const initialState: CostCentersState = {
  costCenters: costCentersEntityAdapter.getInitialState(),
  costCentersLoaded: true,
};

export const costCentersReducer = createReducer(
  initialState,
  on(
    CostCentersActions.fetchCostCenters,
    (state: CostCentersState): CostCentersState => ({
      ...state,
      costCenters: costCentersEntityAdapter.getInitialState(),
      costCentersLoaded: false,
    }),
  ),
  on(
    CostCentersActions.fetchCostCentersSuccess,
    (state: CostCentersState, { costCentersPageResponse: { data } }): CostCentersState => ({
      ...state,
      costCenters: costCentersEntityAdapter.setAll(data, state.costCenters),
      costCentersLoaded: true,
    }),
  ),
  on(
    CostCentersActions.fetchCostCentersError,
    (state: CostCentersState): CostCentersState => ({
      ...state,
      costCenters: costCentersEntityAdapter.getInitialState(),
      costCentersLoaded: true,
    }),
  ),

  on(
    CostCentersActions.upsertCostCenterToList,
    (state: CostCentersState, { costCenter }): CostCentersState => ({
      ...state,
      costCenters: costCentersEntityAdapter.upsertOne(costCenter, state.costCenters),
    }),
  ),
);

export const { selectAll: selectAllCostCenters } = costCentersEntityAdapter.getSelectors();
