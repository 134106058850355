import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { PageResponse } from '@core/shared/domain';
import { CostCenterListItem } from '@mp/organization-master-data/cost-centers/domain';

export const CostCentersActions = createActionGroup({
  source: 'Organization Master Data - Cost Centers',
  events: {
    fetchCostCenters: props<{ searchTerm?: string }>(),
    fetchCostCentersSuccess: props<{ costCentersPageResponse: PageResponse<CostCenterListItem> }>(),
    fetchCostCentersError: emptyProps(),

    upsertCostCenterToList: props<{ costCenter: CostCenterListItem }>(),
  },
});
