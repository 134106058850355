import { EnvironmentProviders, importProvidersFrom, makeEnvironmentProviders } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { CostCentersEffects, CostCentersFacade, costCentersFeatureKey, costCentersReducer } from '../store';

export function provideCostCentersDataAccess(): EnvironmentProviders[] {
  return [
    importProvidersFrom(StoreModule.forFeature(costCentersFeatureKey, costCentersReducer)),
    importProvidersFrom(EffectsModule.forFeature([CostCentersEffects])),
    makeEnvironmentProviders([CostCentersFacade]),
  ];
}
