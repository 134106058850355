import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CostCentersState, costCentersFeatureKey, selectAllCostCenters } from './cost-centers.reducer';

export const selectCostCentersState = createFeatureSelector<CostCentersState>(costCentersFeatureKey);

export const selectCostCenters = createSelector(selectCostCentersState, (state) =>
  selectAllCostCenters(state.costCenters),
);

export const selectCostCentersLoaded = createSelector(selectCostCentersState, (state) => state.costCentersLoaded);
